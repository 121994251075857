import React from 'react';
import { ScReact } from '../ScReact';
import { ScProps } from '../ScProps';
import styles from './CustomerRating.module.scss';
import { CustomerRatingData } from './CustomerRatingData';

export const CustomerRating = (props: ScProps<CustomerRatingData>) => {
  const { link, icon, text, score } = props.data;

  return <ScReact {...props} className={styles.CustomerRatings}>
    <div className={styles.customerRatings}>
      <a href={link.href} target="_new" dangerouslySetInnerHTML={{__html: icon.render}} />

      <a href={link.href} target="_new">
        {text}
      </a>

      <span className="score">
        {score}
      </span>
    </div>
  </ScReact>;
};
