import * as React from 'react';
import { ScReact } from '../ScReact';
import { ScProps } from '../ScProps';
import styles from './ProductSpecialistWide.module.scss';
import { ProductSpecialistData } from './ProductSpecialistData';

export const ProductSpecialistWide = (props: ScProps<ProductSpecialistData>) => {
  const { data } = props;

  return <ScReact {...props} className={styles.ProductSpecialistWide}>
    <div className="container">
      <div className="row">
        <div className={'col-12 col-md-6 text-md-right'}>
          <img src={`${data.image}`} className={styles.image} />
        </div>

        <span className={`${styles.shield} fa cl-shield align-self-center`} />

        <div className={`${styles.content} col-12 col-md-6 align-self-center`}>
          <h1 className={`${styles.title} h1`} dangerouslySetInnerHTML={{ __html: data.title }} />
          <h2 className={`${styles.subText} h2`} dangerouslySetInnerHTML={{ __html: data.subText }} />
          <div className={styles.iconLink}>
            <a className={styles.link} href={`tel:${data.phone}`}>
              <span className={`${styles.icon} fa fa-phone mr-2`} />
              <span className={styles.link} dangerouslySetInnerHTML={{ __html: data.phone }} />
            </a>
          </div>
          <div className={styles.iconLink}>
            <a href={`mailto:${data.email}`}>
              <span className={`${styles.icon} fa fa-envelope mr-2`} />
              <span className={styles.link} dangerouslySetInnerHTML={{ __html: data.emailText }} />
            </a>
          </div>
        </div>
      </div>
    </div>

  </ScReact>;
};
